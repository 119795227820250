<template>
    <div class="row books">
      <div class="col-md-12 mt-2">
        <div class="card">
          <b-row v-if="isError" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                Opps .. Something is wrong
              </b-alert>
            </b-col>
          </b-row>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-sm-7">
                
      <router-link class="btn btn-sm btn-default px-0 pb-2" :to="{name: CustomersBannedPath.name}">
        <em class="fa fa-backward"></em> Manage Customer Banned
      </router-link>
    
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter"
                    class="form-control"
                    placeholder="Search User"
                    @keyup.enter="asyncFind"
                  />
                  <b-input-group-append>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="asyncFind"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <b-col class="text-right">
                <button class="btn btn-sm btn-dark" @click="actionGetScreenShot">
                  <i class="fa fa-refresh"></i>
                </button>
    
                <label for="" class="col-sm-auto">Count </label>
                <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
              </b-col>
            </div>
   
            <b-table
              sticky-header="500px"
              responsive="xl"
              hover
              :items="users"
              :busy="isLoading"
              :isLoading="isLoading"
              :fields="fields"
              show-empty
            >
              <template #cell(No)="row">
                {{ (currentPage - 1) * perPage + (row.index + 1)}}
              </template> 
              <template #cell(Email)="row">
                <div class="text-left">
                  {{ row.item.users ? row.item.users.email:'' }}
                </div>
              </template>
              <template #cell(screenshot_date)="row">
        <div style="width:10em;">
          {{ formatDate(row.item.screenshot_date) }}
        </div>
      </template>
      <template #cell(created)="row">
        <div style="width:10em;">
          {{ formatDate(row.item.created) }}
        </div>
      </template>
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <svg class="heart" viewBox="0 0 32 29.6">
                    <path
                      d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                      c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                    />
                  </svg>
                  Loading ...
                </div>
              </template>
            </b-table>
          </div>
          <b-row class="justify-content-start ml-1">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import moment from "moment";
  //import API from "../../plugins/http"
  import { CustomersBannedPath } from "../../router/customers";

  export default {
    name: "UserBanned",
    data() {
      return {
        currentPage: 1,
        perPage: 100,
        CustomersBannedPath,
        filter: null,
        isLoadingSearch: false,
        items_search: [],
        isBanned: null,
        fields: [
          { key:'No', label: 'No'},
          { key:'screenshot_date', label: 'Screenshot date'},
          { key:'Email', label: 'Email'},
          { key:'created'},
        //   { key:'Status', label: 'Status'},
        ]
      };
    },
    watch: {
      currentPage: function() {
        this.actionGetScreenShot();
      },
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Account Banned | Content Management System Ebooks Gramedia.com';
        }
      },
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.users.isLoading,
        isError: (state) => state.users.isError,
        totalRows: (state) => state.users.totalRowsScreenShot,
        users: (state) => state.users.logscreenshots,
      }),
    },
    mounted() {
      this.actionGetScreenShot();
    },
    methods: {
      ...mapActions("users", ["fetchLogScreenshots","fetchSearchLogScreenshots"]),
      ...mapMutations("users", ["setItems", "setRows"]),
      actionGetScreenShot() {
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
        //  violation_status: this.isBanned,
        };
        this.fetchLogScreenshots(payload);
      },
      async asyncFind() {
        if (!this.filter) return this.actionGetScreenShot();
  
        try {
          let data = {
            email: this.filter,
          //  limit: this.perPage,
          };
          let info = await this.fetchSearchLogScreenshots(data);
          console.log(info);
        } catch (error) {
          console.log(error);
        }
      },
      formatDate(date) {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      },
      formatPriceBasic(value) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  